export const listWebhooks = `query listWebhooks($InstanceId: String!) {
  listWebhooks(InstanceId: $InstanceId) {
    items {
      id
      WebhookProvider
      WebhookType
      Name
      FlowId
      Description
      CreatedAt
      InitialResponse
      FinalResponse
      InstanceId
      WebhookUrl
      TwilioAccountId
      TwilioApiKey
      TwilioApiSecret
      MessageMediaApiKey
      MessageMediaApiSecret
      SmtpHost
      SmtpUserName
      SmtpPass
      SmtpPort
      SmtpUseTls
      LambdaName
      LambdaArn
      SesRegion
      SesBucketName
      AuthType
      StateMachineArn
      TemplateName
      UserCredentials {
        DisplayName
        Name
        Password
      }
      AuthCredentials {
        Username
        Password
      }
      TaskName {
        Name
        Value
        Type
      }
      TaskDescription {
        Name
        Value
        Type
      }
      ReferenceUrl {
        Name
        Value
        Type
      }
      CustomAttributes {
        Name
        Value
        Type
      }
      ProviderPhoneNumber
      ProviderEmail
			OAuthProvider
      InboundEmailAddress
      OutboundEmailAddress
      CustomerHasExternalDomain
      CustomerDkimRecords {
        Name
        Type
        Value
      }
    }
  }
}`;

export const listConversationMessages = `query listConversationMessages($contacts: [String!]) {
  listConversationMessages(contacts: $contacts) {
    conversations {
      conversation {
        range
        InstanceId
        id
        From
        FromName
        To
        ToName
        Type
        IsActive
        Username
        ContactId
        CreatedAt
        Ended
        EndedAt
        MatchedQueueArn
        OriginalMessageId
        MatchedQueueName
        Status
        ChannelId
        ContactRange
        UpdatedAt
        Origin
        Provider
      }
      messages {
        Author
        id
        ConversationId
        Body
        Subject
        TextBody
        BodyUrl
        BodyId
        MediaUrls {
          ContentType
          Url
          Expiration
          Key
        }
        NumMedia
        NumSegments
        CreatedAt
        Direction
        From
        To
        Cc
        Bcc
        Provider
        Type

      }
    }
  }
}`;

export const listConversationMessagesActivity = `query listConversationMessagesActivity($contacts: [String!]) {
  listConversationMessagesActivity(contacts: $contacts) {
    conversations {
      conversation {
        range
        InstanceId
        id
        From
        FromName
        To
        ToName
        Type
        IsActive
        Username
        ContactId
        CreatedAt
        Ended
        EndedAt
        MatchedQueueArn
        OriginalMessageId
        MatchedQueueName
        Status
        ChannelId
        ContactRange
        UpdatedAt
        Origin
        Provider
      }
      messages {
        Author
        id
        ConversationId
        Body
        Subject
        TextBody
        BodyUrl
        BodyId
        MediaUrls {
          ContentType
          Url
          Expiration
          Key
        }
        NumMedia
        NumSegments
        CreatedAt
        Direction
        From
        To
        Cc
        Bcc
        Provider
        Type

      }
    }
  }
}`;

export const getReport = `query getReport($id: String!) {
  getReport(id: $id) {
    id
    Owner
    Status
    Type
    RunNow
    Parent
    ParentId
    NextRunTime
    RelativeTime
    Name
    Units
    Language
    Location
    LocationXlsx
    CompletedAt
    Filters
    Scheduled
    Schedule {
      Frequency
      DeliveryEmails
    }
  }
}`;

export const listReports = `query ListReports($Owner: String) {
  listReports(Owner: $Owner) {
    items {
      id
      Owner
      Type
      Status
      Parent
      NextRunTime
      RunNow
      ParentId
      RelativeTime
      ChildReports {
        id
        CompletedAt
        ParentId
        Status
        Type
        Location
        LocationXlsx
        Owner
        DateRange
        HideNA
      }
      Name
      Units
      Language
      Location
      Filters
      Scheduled
      Schedule {
        Frequency
        DeliveryEmails
      }
      DateRange
      Visibility
      RequestedAt
      CompletedAt
      CreatedAt
      InstanceId
      HideNA
    }
  }
}`;

export const listScheduleEvents = `query ListScheduleEvents($InstanceId: String!, $Queue: String, $Queues: [String]) {
  listScheduleEvents(InstanceId: $InstanceId, Queue: $Queue, Queues: $Queues) {
    items {
      id
      InstanceId
      Queues
      startTime
      endTime
      isRecurring
      Name
      isMultipleDays
      isAllDayStart
      isAllDayEnd
      tts
    }
    limit
  }
}
`;

export const listLivelookContacts = `query listLivelookContacts($InstanceId: String!, $Queues: [String]) {
  listLivelookContacts(InstanceId: $InstanceId, Queues: $Queues) {
    items {
      id
      QueueName
      PreviousContactId
      QueueArn
      EnqueueTimestamp
      Channel
      CustomerEndpoint
      CustomerName
      CustomerId
      Validated
    }
  }
}
`;

export const getTasks = `query GetUser($InstanceId: String!, $Username: String!) {
  getUser(InstanceId: $InstanceId, Username: $Username) {
    Tasks,
    Preferences {
      FavoriteTabName
      IsEnabledGoToApplication
    }
  }
}`;

export const getColumnSettings = `query getColumnSettings($InstanceId: String!, $Username: String!, $Type: String!) {
  getColumnSettings(InstanceId: $InstanceId, Username: $Username, Type: $Type)
}`;

export const getUser = `query GetUser($InstanceId: String!, $Username: String!) {
  getUser(InstanceId: $InstanceId, Username: $Username) {
    AWSAccountID
    Password
    InstanceId
    InstanceAlias
    Id
    Contact
    FavoriteTeamMembers
    FavoriteDirectoryIds
    Arn
    FirstName
    Region
    LastName
    Username
    CivicAddress
    Queues
    StatusName
    Tasks
    NeedsHelp
    StatusStartTimestamp
    Email
    StatusDuration
    Role
    Roles
    Permissions
    SecurityProfileId
    StatusType
    SecurityProfile {
      id
      Name
      Description
      SoftPhone
      PermissionGroups {
        Name
        Order
        PermissionItems {
          Action
          Name
          Values {
            All
            View
            Edit
            Create
            Remove
            Listen
            Download
            Inbound
            Outbound
            Enable
            Share
          }
        }
      }
    }
    IdentityInfo {
      FirstName
      LastName
      Email
    }
    PhoneConfig {
      PhoneType
      AutoAccept
      AfterContactWorkTimeLimit
      DeskPhoneNumber
      DefaultCountry {
        Name
        ISO
      }
    }
    DirectoryUserId
    SecurityProfileIds
    RoutingProfileId {
      Name
      Arn
      Id
    }
    Preferences {
      Language
      JabraGen
      Avatar
      ColumnArrangments {
        Type
        Data
      }
      TeamStatusView {
        OfflineHidden
        MinimizeRecent
        MinimizeFavorites
        isOpen
        showAllUsers
      }
      PrimaryRinger {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      SecondaryRinger {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      PrimaryRingerPeer {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      SecondaryRingerPeer {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      PrimaryRingerEmail {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      SecondaryRingerEmail {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      PrimaryRingerSMS {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      SecondaryRingerSMS {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      AlertTone
      AgentCallTone
      CallEndTone
      AgentMessageTone
      PrimaryRinger {
        Volume
        Sound
        DeviceId
        isEnabled
      }
      SecondaryRinger {
        Sound
        Volume
        DeviceId
        isEnabled
      }
      DeviceId
      Theme
      IsEnabledGoToApplication
      DashboardCards {
        colOne {
          component
          id
          isShow
          name
        }
        colTwo {
          component
          id
          isShow
          name
        }
        colThree {
          component
          id
          isShow
          name
        }
      }
    }
  }
}
`;

export const getUserPreferences = `query GetUser($InstanceId: String!, $Username: String!) {
  getUser(InstanceId: $InstanceId, Username: $Username) {
    Id
    PhoneConfig {
      PhoneType
      AutoAccept
      AfterContactWorkTimeLimit
      DeskPhoneNumber
      DefaultCountry {
        Name
        ISO
      }
    }
    CivicAddress
    Preferences {
      FavoriteTabName
      Language
      JabraGen
      Avatar
      ColumnArrangments {
        Type
        Data
      }
      TeamStatusView {
        OfflineHidden
        MinimizeRecent
        MinimizeFavorites
        isOpen
        showAllUsers
      }
      PrimaryRinger {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      SecondaryRinger {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      PrimaryRingerPeer {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      SecondaryRingerPeer {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      PrimaryRingerEmail {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      SecondaryRingerEmail {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      PrimaryRingerSMS {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      SecondaryRingerSMS {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      AlertTone
      AgentCallTone
      AgentMessageTone
      CallEndTone
      PrimaryRinger {
        Volume
        Sound
        DeviceId
        isEnabled
      }
      SecondaryRinger {
        Sound
        Volume
        DeviceId
        isEnabled
      }
      DeviceId
      Theme
      IsEnabledGoToApplication
      DashboardCards {
        colOne {
          component
          id
          isShow
          name
        }
        colTwo {
          component
          id
          isShow
          name
        }
        colThree {
          component
          id
          isShow
          name
        }
      }
    }
  }
}
`;

export const listSecurityProfiles = `query listSecurityProfiles($InstanceId: String!) {
  listSecurityProfiles(InstanceId: $InstanceId) {
    items {
      id
      Name
      Realm
      Description
      isDefault
      SoftPhone
      PermissionGroups {
        Name
        Order
        PermissionItems {
          Action
          Name
          Values {
            All
            View
            Edit
            Create
            Remove
            Listen
            Download
            Inbound
            Outbound
            Enable
            Share
          }
        }
      }
    }
  }
}
`;

export const getAccount = `query getAccount(
  $InstanceId: String!
) {
  getAccount(
    InstanceId: $InstanceId
  ) {
      InstanceId
      Queues {
        Arn
        Name
        id
      }
      RoutingProfile {
        MediaConcurrencies {
          Channel
          Concurrency
          CrossChannelBehavior {
            BehaviorType
          }
        }
      }
      CognitoPoolId
      CompanyName
      AdminLogo
			LogoMediaURL
      AdminMail
      AdminName
      AWSAccountID
      InstanceAlias
      AccessKeyId
      RoleArn
      SecretAccessKey
      DomainName
      TimeZone {
        value
        abbr
        isdst
        text
        offset
        utc
      }
     BucketName
     LastAgentEvent
     LastCtrEvent
     LastContactEvent
     Region
    }
  }
`;

export const listContacts = `query ListContacts($InstanceId: String!, $UserId: String!) {
  listContacts(InstanceId: $InstanceId, UserId: $UserId) {
    items {
      InstanceId
      UserId
      Id
      Name
      Email
      WorkPhone
      HomePhone
      CellPhone
      IsFavorite
      IsGlobal
    }
  }
}
`;

export const listSupportCases = `query ListSupportCases($account: String!) {
  listSupportCases(account: $account) {
    items {
      number
      contact
      account
      priority
      category
      subcategory
      short_description
      description
      state
      sys_updated_on
      first_name
      last_name
      email
      phone
      registration_code
      case_url
    }
  }
}
`;

export const verifyCreds = `query verifyCreds($input: VerifyCredsInput!) {
  verifyCreds(input: $input) {
    Access
    Secret
    Region
    RoleArn
  }
}
`;

export const getHealth = `query GetHealth($Event: String) {
  getHealth(Event: $Event) {
    events {
      arn
      service
      eventTypeCode
      eventTypeCategory
      region
      statusCode
      startTime
      endTime
      lastUpdatedTime
      latestDescription
    }
  }
}
`;

export const listContactFlows = `query listContactFlows($InstanceId: String!) {
listContactFlows(InstanceId: $InstanceId) {
    items {
      Id
      Arn
      Name
    }
  }
}`;

export const getQueuesWithRoutingProfiles = `query getQueuesWithRoutingProfiles($InstanceId: String) {
getQueuesWithRoutingProfiles(InstanceId: $InstanceId) {
    items {
      QueueId
      QueueArn
      QueueName
      Priority
      Delay
      Channel
      RoutingProfileSummaryList {
        Id
        Arn
        Name
      }
    }
  }
}`;

export const listAnnouncements = `query listAnnouncements($InstanceId:String!, $Queues: [String], $cursor: String, $limit: Int) {
  listAnnouncements(InstanceId: $InstanceId, Queues: $Queues, limit: $limit, cursor: $cursor) {
    items {
      id
      Message
      From
      Queues
      Urgency
      Title
      Date
      DeliveryMethods
    }
    limit
    cursor
  }
}`;

export const getQueueMetrics = `query GetQueueMetrics($InstanceId: String!, $Queues: [String!], $DateRange: [String!], $Period: String, $IsHistorical: Boolean ) {
  getQueueMetrics(InstanceId: $InstanceId, Queues: $Queues, DateRange: $DateRange, Period: $Period, IsHistorical: $IsHistorical) {
    items {
      id
      Arn
      Name
      Channel
      OutboundCallerConfig {
        OutboundCallerIdName
        OutboundCallerIdNumberId
        OutboundFlowId
      }
      Skills {
        id
        Title
        Status
        InstanceId
        Level
      }
      Status
      Open
      Description
      HoursOfOperationId
      QueueId
      QueueArn
      MaxContacts
      Tags
      Contacts {
        InitiationTimestamp
        id
        InitiationMethod
        CustomerEndpoint
        Channel
        Queue
      }
      ReportRange {
        start
        end
      }
      CsatPerformance {
        SurveyNames
        TotalSurveys
        TotalResponses
        Results
      }
      Performance {
        Name
        Status
        Open
        AvgACW
        AHT
        Holds
        HoldDuration
        HandledCallbacks
        LongestHold
        HandledIn
        HandledOut
        HandledSms
        HandledEmail
        HandledChat
        Missed
        Abandoned
      }
      AgentPerformance {
        AvgACW
        AHT
        Holds
        HoldDuration
        HandledCallbacks
        LongestHold
        HandledIn
        HandledOut
        HandledSms
        HandledEmail
        HandledChat
        Missed
        Username
      }
      DataSnapshotTime
      TimeSeriesInterval
      TimeSeriesData {
        AvgWaitTime
        AvgHandlingTime
        AvgAbandonTime
        AvgWrapTime
        AvgHoldTime
        OccupancyPct
        MaxWaitTime
        ContactsMissed
        AbandonedPct
        ServiceLvlPct
        ContactsQueued
        ContactsHandled
        ContactsHandledIn
        ContactsHandledCallbacks
        ContactsHandledOut
        ContactsAbandoned
        ContactsHoldAbandoned
        ContactsInQueueVoice
        ContactsInQueueChat
        ContactsInQueueEmail
        ContactsInQueueCallbacks
        ContactsInQueueSms
        ServiceLevel_10
        ServiceLevel_15
        ServiceLevel_20
        ServiceLevel_30
        ServiceLevel_60
        ServiceLevel_90
        ServiceLevel_120
        ServiceLevel_180
        ServiceLevel_240
        ServiceLevel_300
      }
      NewMetricData {
        AvgWaitTime
        AvgHandlingTime
        AvgAbandonTime
        AvgWrapTime
        AvgHoldTime
        OccupancyPct
        MaxWaitTime
        ContactsMissed
        AbandonedPct
        ServiceLvlPct
        ContactsQueued
        ContactsHandled
        ContactsHandledIn
        ContactsHandledCallbacks
        ContactsHandledOut
        ContactsAbandoned
        ContactsHoldAbandoned
        ContactsInQueueVoice
        ContactsInQueueChat
        ContactsInQueueEmail
        ContactsInQueueCallbacks
        ContactsInQueueSms
        ContactsTransferedExternally
        ServiceLevel_10
        ServiceLevel_15
        ServiceLevel_20
        ServiceLevel_30
        ServiceLevel_60
        ServiceLevel_90
        ServiceLevel_120
        ServiceLevel_180
        ServiceLevel_240
        ServiceLevel_300
        AgentsAfterContactWork
        AgentsAvailable
        AgentsError
        AgentsNonProductive
        AgentsOnCall
        AgentsOnContact
        AgentsOnline
        AgentsStaffed
        ContactsInQueue
        ContactsScheduled
      }
    }
    summary {
      Name
      Arn
      id
      TimeSeriesData {
        AvgWaitTime
        AvgHandlingTime
        AvgAbandonTime
        AvgWrapTime
        AvgHoldTime
        OccupancyPct
        MaxWaitTime
        ContactsMissed
        AbandonedPct
        ServiceLvlPct
        ContactsQueued
        ContactsHandled
        ContactsHandledIn
        ContactsHandledCallbacks
        ContactsHandledOut
        ContactsAbandoned
        ContactsHoldAbandoned
        ContactsInQueueVoice
        ContactsInQueueChat
        ContactsInQueueEmail
        ContactsInQueueCallbacks
        ContactsInQueueSms
        ContactsTransferedExternally
        ServiceLevel_10
        ServiceLevel_15
        ServiceLevel_20
        ServiceLevel_30
        ServiceLevel_60
        ServiceLevel_90
        ServiceLevel_120
        ServiceLevel_180
        ServiceLevel_240
        ServiceLevel_300
      }
      NewMetricData {
        AvgWaitTime
        AvgHandlingTime
        AvgAbandonTime
        AvgWrapTime
        AvgHoldTime
        OccupancyPct
        MaxWaitTime
        ContactsMissed
        AbandonedPct
        ServiceLvlPct
        ContactsQueued
        ContactsHandled
        ContactsHandledIn
        ContactsHandledCallbacks
        ContactsHandledOut
        ContactsAbandoned
        ContactsHoldAbandoned
        ContactsInQueueVoice
        ContactsInQueueChat
        ContactsInQueueEmail
        ContactsInQueueCallbacks
        ContactsInQueueSms
        ContactsTransferedExternally
        ServiceLevel_10
        ServiceLevel_15
        ServiceLevel_20
        ServiceLevel_30
        ServiceLevel_60
        ServiceLevel_90
        ServiceLevel_120
        ServiceLevel_180
        ServiceLevel_240
        ServiceLevel_300
        AgentsAfterContactWork
        AgentsAvailable
        AgentsError
        AgentsNonProductive
        AgentsOnCall
        AgentsOnContact
        AgentsOnline
        AgentsStaffed
        ContactsInQueue
        ContactsScheduled
      }
      CsatPerformance {
        SurveyNames
        TotalSurveys
        TotalResponses
        Results
      }
      Performance {
        Name
        Status
        Open
        AvgACW
        AHT
        Holds
        HoldDuration
        HandledCallbacks
        LongestHold
        HandledIn
        HandledOut
        HandledSms
        HandledEmail
        HandledChat
        Missed
        Abandoned
      }
      AgentPerformance {
        AvgACW
        AHT
        Holds
        HoldDuration
        HandledCallbacks
        LongestHold
        HandledIn
        HandledOut
        HandledSms
        HandledEmail
        HandledChat
        Missed
        Username
      }
      Contacts {
        InitiationTimestamp
        id
        InitiationMethod
        CustomerEndpoint
        Channel
        Queue
      }
    }
  }
}
`;

export const listOwnedPhoneNumbers = `query listOwnedPhoneNumbers($InstanceId:String) {
  listOwnedPhoneNumbers(InstanceId: $InstanceId) {
    items {
      id
      QueueARNs
      DefaultQueueARN
      FriendlyName
      PhoneNumber
      Lata
      Locality
      RateCenter
      AddressRequirements
      IsoCountry
      Beta
      IsAssignedToFlow
      Latitude
      Longitude
      Region
      PostalCode
      Country
      Capabilities {
        voice
        SMS
        MMS
      }
      DateCreated
      DateUpdated
      Origin
      AccountSid
      Sid
      IdentitySid
      SmsApplicationSid
      SmsFallbackMethod
      SmsFallbackUrl
      SmsMethod
      SmsUrl
      EmergencyAddressSid
      StatusCallback
      StatusCallbackMethod
      TrunkSid
      Uri
    }
  }
}`;

export const listAvailablePhoneNumbers = `query listAvailablePhoneNumbers($filter: PhoneNumberFilter) {
  listAvailablePhoneNumbers(filter: $filter) {
    items {
      FriendlyName
      PhoneNumber
      Lata
      Locality
      RateCenter
      AddressRequirements
      IsoCountry
      Beta
      Latitude
      Longitude
      Region
      PostalCode
      Capabilities {
        voice
        SMS
        MMS
      }
    }
  }
}`;

export const listSurveys = `query listSurveys($InstanceId: String!) {
  listSurveys(InstanceId: $InstanceId) {
    items {
      id
      Name
      Description
      UniqueAttribute
      Questions {
        Text
        Attribute
        Answers {
          Text
          Value
        }
      }
      CreatedAt
      UpdatedAt
      ContactFlowId
    }
  }
}
`;

export const listTeam = `query ListTeam($InstanceId: String!, $Queues: [String], $Limit: Int, $PageNumber: Int, $Search: String, $ListToSearch: [String], $UserId: String, $isNeedsHelp: Boolean, $ShowAvailableUsers: Boolean) {
  listTeam(InstanceId: $InstanceId, Queues: $Queues, Limit: $Limit , PageNumber: $PageNumber, Search: $Search, ListToSearch: $ListToSearch, UserId: $UserId, isNeedsHelp: $isNeedsHelp, ShowAvailableUsers: $ShowAvailableUsers ) {
    Count
    Items {
      isBeingCoached
      isBeingMonitored
      InstanceId
      Id
      Contact
      Contacts
      StatusStartTimestamp
      NeedsHelp
      Username
      StatusName
      StatusType
      StatusDuration
      Platform
      Queues
      HeartBeat
      IdentityInfo {
        FirstName
        LastName
        Email
      }
    }
  }
}
`;

export const listRoutingProfiles = `
query listRoutingProfiles($InstanceId: String!) {
  listRoutingProfiles(InstanceId: $InstanceId) {
    items {
      Name
      Arn
      Id
    }
  }
}`;

export const listUsersCompact = `query ListUsers(
  $Queues: [String]
  $InstanceId: String!
  $limit: Int
  $cursor: String
) {
  listUsers(
    Queues: $Queues
    InstanceId: $InstanceId
    limit: $limit
    cursor: $cursor
  ) {
    count
    cursor
    items {
      InstanceId
      Id
      Queues
      Username
      SecurityProfileId
      StatusName
      StatusStartTimestamp
      SecurityProfile {
        id
        Name
        Description
      }
      IdentityInfo {
        FirstName
        LastName
        Email
      }
      PhoneConfig {
        PhoneType
        AutoAccept
        AfterContactWorkTimeLimit
        DeskPhoneNumber
        DefaultCountry {
          Name
          ISO
        }
      }
      RoutingProfileId {
        Name
        Arn
        Id
      }
    }
  }
}
`;

export const listUsers = `query ListUsers(
  $Queues: [String]
  $InstanceId: String!
  $limit: Int
) {
  listUsers(
    Queues: $Queues
    InstanceId: $InstanceId
    limit: $limit
  ) {
    items {
      Password
      InstanceId
      InstanceAlias
      isBeingCoached
      isBeingMonitored
      StatusStartTimestamp
      Id
      Arn
      FirstName
      Contact
      LastName
      Username
      Queues
      StatusName
      Email
      isConnectedToWebRTCCall
      StatusDuration
      Role
      Roles
      Permissions
      SecurityProfileId
      SecurityProfile {
        id
        Name
        Description
        SoftPhone
        PermissionGroups {
          Name
          Order
          PermissionItems {
            Action
            Name
            Values {
              All
              View
              Edit
              Create
              Remove
              Listen
              Download
              Inbound
              Outbound
              Enable
              Share
            }
          }
        }
      }
      IdentityInfo {
        FirstName
        LastName
        Email
      }
      PhoneConfig {
        PhoneType
        AutoAccept
        AfterContactWorkTimeLimit
        DeskPhoneNumber
        DefaultCountry {
          Name
          ISO
        }
      }
      DirectoryUserId
      SecurityProfileIds
      RoutingProfileId {
        Name
        Arn
        Id
      }
      Preferences {
        Avatar
        JabraGen
        ColumnArrangments {
          Type
          Data
        }
        PrimaryRinger {
          Sound
          isEnabled
          DeviceId
          Volume
        }
        SecondaryRinger {
          Sound
          isEnabled
          DeviceId
          Volume
        }
        PrimaryRingerPeer {
        Sound
        isEnabled
        DeviceId
        Volume
        }
        SecondaryRingerPeer {
          Sound
          isEnabled
          DeviceId
          Volume
        }
        PrimaryRingerEmail {
          Sound
          isEnabled
          DeviceId
          Volume
        }
        SecondaryRingerEmail {
          Sound
          isEnabled
          DeviceId
          Volume
        }
        PrimaryRingerSMS {
          Sound
          isEnabled
          DeviceId
          Volume
        }
        SecondaryRingerSMS {
          Sound
          isEnabled
          DeviceId
          Volume
        }
        AlertTone
        DeviceId
        Theme
        DashboardCards {
          colOne {
            component
            id
            isShow
            name
          }
          colTwo {
            component
            id
            isShow
            name
          }
          colThree {
            component
            id
            isShow
            name
          }
        }
      }
    }
  }
}
`;

export const listDispositions = `query ListDispositions($InstanceId: String!, $Queues: [QueueInput]) {
  listDispositions(InstanceId: $InstanceId, Queues: $Queues) {
    items {
      id
      Name
      Queues {
        Arn
        Name
      }
    }
  }
}
`;

export const getLevelLists = `query getLevelLists($InstanceId: String, $id: String, $Level: Int, $ParentId: String, $Queues: [QueueInput]) {
  getLevelLists(InstanceId: $InstanceId, id: $id, Level: $Level, ParentId: $ParentId, Queues: $Queues) {
    items {
      id
      InstanceId
      Name
      ListType
      Level
      Queues {
        Arn
        Name
      }
      SeriesId
      ParentName
      ParentId
      CTRName
      CTRValue
      ItemName
      Status
    }
  }
}`;

export const listMyActivity = `query ListActivity(
  $InstanceId: String!
  $Username: String
  $Queues: [String]
  $limit: Int
  $cursor: String
  $filter: String
) {
  listActivity(
    InstanceId: $InstanceId
    Username: $Username
    Queues: $Queues
    limit: $limit
    cursor: $cursor
    filter: $filter
  ) {
    items {
      id
      Username
      Duration
      Channel
      InitiationMethod
      Source
      CustomerEndpoint
      SystemEndpoint
      InitiationTimestamp
      CannedScriptUsed
      Disposition
      DisconnectTimestamp
      Queue
      Transcript
    }
    limit
    cursor
  }
}
`;
export const listActivity = `query ListActivity(
  $InstanceId: String!
  $id: String
  $Username: String
  $Queues: [String]
  $limit: Int
  $cursor: String
  $filter: String
  $deep: Boolean
  $includeSummary: Boolean
  $token: String
  $activity_permission: ActivityPermissionEnum
) {
  listActivity(
    InstanceId: $InstanceId
    id: $id
    Username: $Username
    Queues: $Queues
    limit: $limit
    cursor: $cursor
    filter: $filter
    deep: $deep
    token: $token
    includeSummary: $includeSummary
    activity_permission : $activity_permission
  ) {
    items {
      id
      Username
      Channel
      InitiationMethod
      Source
      CustomerEndpoint
      Duration
      InitiationTimestamp
      DisconnectTimestamp
      CannedScriptUsed
      Queue
      Source
      Disposition
      Type
      Transcript
      QueueDetails {
        Duration
      }
      Recording {
        Location
      }
    }
    limit
    cursor
    summary
    count
  }
}
`;
export const listCtr = `query listCtr(
  $InstanceId: String!
  $id: String
  $Username: String
  $Queues: [String]
  $limit: Int
  $cursor: String
  $filter: String
  $deep: Boolean
  $includeSummary: Boolean
  $token: String
  $activity_permission: ActivityPermissionEnum
) {
  listCtr(
    InstanceId: $InstanceId
    id: $id
    Username: $Username
    Queues: $Queues
    limit: $limit
    cursor: $cursor
    filter: $filter
    deep: $deep
    token: $token
    includeSummary: $includeSummary
    activity_permission : $activity_permission
  ) {
    items {
      id
      Username
      Channel
      InitiationMethod
      CustomerEndpoint
      Duration
      InitiationTimestamp
      DisconnectTimestamp
      DisconnectReason
      Queue
      CannedScriptUsed
      MOS
      Disposition
      Type
      Transcript
      QueueDetails {
        Duration
      }
      Recording {
        Location
      }
      Attributes {
        name
        value
      }
    }
    limit
    cursor
    summary
    count
    totalCount
    areTherePendingItems
  }
}
`;

export const listRelatedActivity = `query listRelatedActivity(
  $InstanceId: String!
  $id: String
  $Username: String
  $Queues: [String]
  $limit: Int
  $cursor: String
  $filter: String
  $deep: Boolean
  $includeSummary: Boolean
  $token: String
  $activity_permission: ActivityPermissionEnum
) {
  listRelatedActivity(
    InstanceId: $InstanceId
    id: $id
    Username: $Username
    Queues: $Queues
    limit: $limit
    cursor: $cursor
    filter: $filter
    deep: $deep
    token: $token
    includeSummary: $includeSummary
    activity_permission : $activity_permission
  ) {
    items {
      id
      Username
      Channel
      InitiationMethod
      InitiationTimestamp
    }
    limit
    count
    totalCount
    areTherePendingItems
  }
}
`;

export const exportActivity = `query exportActivity($type: String!, $filter: [String], $deep: Boolean, $token: String) {
  exportActivity(type: $type, filter: $filter, deep: $deep, token: $token) {
    CSVString
  }
}
`;
export const listAssociatedActivity = `query ListActivity(
  $InstanceId: String!
  $id: String
  $Username: String
  $Queues: [String]
  $limit: Int
  $cursor: String
  $filter: String
  $deep: Boolean
) {
  listActivity(
    InstanceId: $InstanceId
    id: $id
    Username: $Username
    Queues: $Queues
    limit: $limit
    cursor: $cursor
    filter: $filter,
    deep: $deep
  ) {
    items {
      id
      Username
      Channel
      InitiationMethod
      Source
      CustomerEndpoint
      InitiationTimestamp
      DisconnectTimestamp
      CannedScriptUsed
      Notes {
        id
        Body
        Agent
        CreatedAt
        ContactId
      }
      Queue
      Disposition
      Type
      QueueDetails {
        Duration
      }
    }
    limit
    cursor
  }
}
`;

export const describeActivity = `query describeActivity($id: String!, $InstanceId: String!, $deep: Boolean) {
  describeActivity(id: $id, InstanceId: $InstanceId, deep: $deep) {
    id
    Attributes {
      name
      value
    }
    NextContactId
    Analysis
    PreviousContactId
    InitialContactId
    InstanceId
    Username
    AgentConnectionAttempts
    PreSignedRecordingUrl
    PreSignedScreenRecordingUrl
    TransferredToEndpoint
    TransferCompletedTimestamp
    DisconnectReason
    DeliveryMethods
    Transcript
    Channel
    InitiationMethod
    CustomerEndpoint
    SystemEndpoint
    Transcript
    InitiationTimestamp
    DisconnectTimestamp
    Queue
    Queues
    Duration
    Connections
    Disposition
    ContactDetails {
      Name
      Description
    }
    References {
      Type
      Name
      Value
    }
    Type
    Notes {
      id
      Title
      Body
      Agent
      CreatedAt
      ContactId
    }
    AssociatedContacts {
      id
      Username
      CustomerEndpoint
      InitiationMethod
      InitiationTimestamp
      Channel
      Notes {
        id
        Body
        Agent
        CreatedAt
        ContactId
      }
    }
    Recording {
      Location
      Status
      Type
      DeletionReason
    }
    Recordings{
      Location
      MediaStreamType
    }
    Agent {
      ARN
      AfterContactWorkDuration
      AfterContactWorkEndTimestamp
      AfterContactWorkStartTimestamp
      AgentInteractionDuration
      ConnectedToAgentTimestamp
      CustomerHoldDuration
      LongestHoldDuration
      NumberOfHolds
      Username
    }
    QueueDetails {
      ARN
      DequeueTimestamp
      Duration
      EnqueueTimestamp
      Name
    }
  }
}`;

export const getNetworkTraversal = `query getNetworkTraversal {
  getNetworkTraversal {
      IceServers {
        url
        username
        credential
      }
  }
}`;

export const getAvailablePhoneNumber = `query getAvailablePhoneNumber {
    getAvailablePhoneNumber {
      FriendlyName
      PhoneNumber
      Lata
      Locality
      RateCenter
      AddressRequirements
      IsoCountry
      Beta
      Latitude
      Longitude
      Region
      PostalCode
      Country
      Capabilities {
        voice
        SMS
        MMS
      }
    }
  }`;

export const getSecurityProfile = `query getSecurityProfile(
  $InstanceId: String,
  $id: String!,
  $ConnectProfiles: [String]
) {
  getSecurityProfile(
    InstanceId: $InstanceId
    id: $id,
    ConnectProfiles: $ConnectProfiles
  ) {
    id
    Name
    Realm
    SoftPhone
    Description
    ConnectPermissions
    PermissionGroups {
      Name
      Order
      PermissionItems {
        Action
        Name
        Values {
          All
          View
          Edit
          Create
          Remove
          Listen
          Download
          Inbound
          Outbound
          Enable
          Share
        }
      }
    }
  }
}
`;

export const getSettings = `query getSettings($InstanceId: String!) {
  getSettings(
    InstanceId: $InstanceId
  ) {
    Theme
    MultiLevelSeries
    PresentedNotAnswered {
      Status
      Label
      Duration
    }
    LiveLook {
      isEnabled
    }
    Reporting {
      isEnabled
      isAllowed
    }
    ContactLens {
      isAllowed
      isEnabled
    }
    SAML {
      isEnabled
      loginUrl
    }
    CallDispositions {
      isEnabled
      allowCustomInput
      isMandatory
      isMultiLevelMandatory
    }
    ChangeOutboundQueues {
      isEnabled
      Queues
    }
    CustomCRM {
      isAllowed
      isEnabled
      id
    }
    DefaultCountry {
      Name
      ISO
    }
    OutboundMessages{
      isEnabled
      ContactFlow
    }
    ThirdPartyIntegrations
    DefaultOutboundChannels {
      EmailChannelId
      SmsChannelId
    }
    DefaultContactFlow {
      FlowId
      FlowName
    }
    IdleActive
    IdleStatus
    IdleTime
    LegacyWebhookChannelBehavior
    ComprehendIntegration
    NewStatusBar
    DefaultTaskTemplateId
    QuickSightReadOnlyUser
    QuickSightPricing
    EmailAliases {
      Id
      EmailAddress
    }
    ContactAttributesFilter {
      Id
      ContactAttributeKey
    }
  }
}
`;

export const listSmsMessages = `query listSmsMessages($InstanceId: String!, $ContactId: String!) {
  listSmsMessages(InstanceId: $InstanceId, ContactId: $ContactId) {
    items {
      id
      InstanceId
      ContactId
      Author
      Body
      MediaUrls {
        ContentType
        Url
      }
      Direction
      CreatedAt
      Direction
      NumMedia
      NumSegments
      SmsStatus
      MessageSid
      SmsMessageSid
    }
  }
}
`;

export const listSmsFlows = `query ListSmsFlows($InstanceId: String!) {
  listSmsFlows(InstanceId: $InstanceId) {
    items {
      id
      Name
      InstanceId
      WebhookIds
      KeywordMap {
        Keywords
        QueueArns
        Position
      }
      DefaultDestination
      InitialResponse
      FinalResponse
      CreatedAt
    }
  }
}
`;

export const listAgentChatConversations = `query listAgentChatConversations($InstanceId: String!, $Participants: [String]) {
  listAgentChatConversations(InstanceId: $InstanceId, Participants: $Participants) {
    items {
      InstanceId
      id
      UpdatedAt
      UnreadMessageCount
      UnreadMessages {
        Author
        id
        Body
        MessageError
        MessageStatus
        ContentType
        CreatedAt
        UpdatedAt
        SentAt
        EventType
      }
      Messages {
        InstanceId
        Author
        id
        ConversationId
        Body
        MessageError
        MessageStatus
        ContentType
        CreatedAt
        UpdatedAt
        SentAt
        EventType
      }
      Participants {
        Username
        ConversationId
        IsTyping
      }
      EventType
    }
  }
}
`;

export const listAgentChatMessages = `query listAgentChatMessages($InstanceId: String!, $ConversationId: String!) {
  listAgentChatMessages(InstanceId: $InstanceId, ConversationId: $ConversationId) {
    items {
      InstanceId
      Author
      id
      ConversationId
      Body
      MessageError
      MessageStatus
      ContentType
      CreatedAt
      UpdatedAt
      SentAt
      EventType
    }
  }
}
`;

export const getAvatar = `query GetAvatar($InstanceId: String!, $Username: String!) {
  getUser(InstanceId: $InstanceId, Username: $Username) {
    InstanceId
    Preferences {
      Avatar
    }
  }
}`;

export const getAgentChatConversation = `query getAgentChatConversation($InstanceId: String!, $id: String!) {
  getAgentChatConversation(InstanceId: $InstanceId, id: $id) {
    InstanceId
    id
    CreatedAt
    UpdatedAt
      Messages {
      Author
      id
      Body
      MessageError
      MessageStatus
      ContentType
      CreatedAt
      UpdatedAt
      SentAt
      EventType
    }
    Participants {
      Username
      IsTyping
    }
    EventType
  }
}`;

export const listEmailFlows = `query ListEmailFlows($InstanceId: String!) {
  listEmailFlows(InstanceId: $InstanceId) {
    items {
      id
      InstanceId
      Name
      EmailServerConfigId
      KeywordMap {
        Keywords
        QueueArns
        Position
      }
      DefaultDestination
      CreatedAt
      KeywordMap {
        QueueArns
      }
      DefaultDestination
      InitialResponse
      FinalResponse
    }
  }
}`;

export const listEmailContacts = `query listEmailContacts($InstanceId: String!, $Username: String!, $All: Boolean) {
  listEmailContacts(InstanceId: $InstanceId, Username: $Username, All: $All) {
    items {
      id
      CustomerEmail
      AgentEmail
      CreatedAt
      IsActive
      Accepted
      Status
      Messages {
        Subject
        BodyText
        BodyHtml
        CreatedAt
      }
      AcceptedAt
      RejectedAt
    }
  }
}`;

export const listEmailMessages = `query listEmailMessages($InstanceId: String!, $ContactId: String!) {
  listEmailMessages(InstanceId: $InstanceId, ContactId: $ContactId) {
    items {
      InstanceId
      Author
      id
      ContactId
      CustomerEmail
      AgentEmail
      MessageId
      Subject
      BodyText
      BodyHtml
      ForwardedMessageId
      AttachmentCount
      Direction
      CreatedAt
      error {
        id
        message
        type
      }
    }
  }
}`;

export const listEmailServerConfigs = `query listEmailServerConfigs($InstanceId: String!) {
  listEmailServerConfigs(InstanceId: $InstanceId) {
    items {
      id
      ImapHost
      ImapUserName
      ImapPort
      ImapUseTls
      ImapPass
      SmtpHost
      SmtpUserName
      SmtpPort
      SmtpPass
      IsAssignedToFlow
    }
  }
}`;

export const listActivityNotes = `query listActivityNotes($InstanceId: String!, $CustomerEndpoint: String, $ContactId: String) {
  listActivityNotes(CustomerEndpoint: $CustomerEndpoint, InstanceId: $InstanceId, ContactId: $ContactId) {
    items {
      id
      Title
      Body
      Agent
      CustomerEndpoint
      CreatedAt
      ContactId
    }
  }
}`;

export const getCustomCRM = `query getCustomCRM($id: String!) {
  getCustomCRM(id: $id) {
    id
    AuthenticationType
    SubmitEndpointUrl
    CancelEndpointUrl
    Username
    Password
    AppKey {
      name
      value
    }
  }
}`;

export const latency = `query getLatency($test: String) {
  getLatency(test: $test) {
    test
  }
}`;

export const getScriptList = `query getScriptList($InstanceId: String, $Channels: [String] , $Queues: [QueueInput]){
  getScriptList(InstanceId: $InstanceId,Channels: $Channels , Queues: $Queues ){
    items {
      id
      InstanceId
      Username
      Title
      Text
      Queues {
        id
        Arn
        Name
      }
      Channels
      Scope
      Role
    }
  }
}`;

export const getChatSuggestions = `query getChatSuggestions($question: String!, $isSummary: Boolean) {
  getChatSuggestions(question: $question, isSummary: $isSummary) {
    items {
      suggestion
    }
  }
}`;

export const getCurrentGmtEpochTimeStamp = `query getCurrentGmtEpochTimeStamp {
  getCurrentGmtEpochTimeStamp
}`;

export const listThirdPartyIntegration = `query listThirdPartyIntegration {
  listThirdPartyIntegration {
    Id
    Name
    BaseUrl
    TabName
    Type
    Queues {
      id
      Arn
      Name
    }
    BrowserTab
  }
}`;

export const getThirdPartyIntegration = `query getThirdPartyIntegration($input: ThirdPartyIntegrationGetInput!) {
  getThirdPartyIntegration(input: $input) {
      Id
      Name
      BaseUrl
      TabName
      Type
      BrowserTab
  }
}`;

export const listPublicAPIBearerToken = `query listPublicAPIBearerToken($InstanceId: String, $Username: String) {
  listPublicAPIBearerToken(InstanceId: $InstanceId, Username: $Username) {
    items{
      id
      Title
      Token
      CreatedAt
      InstanceId
      Username
    }
  }
}`;

export const listTranscriptsHistory = `query listTranscriptsHistory($InstanceId: String, $customerEndpoint: String, $Cursor: String, $CurrentDate: String, $Channel: String, $EndDateRange: String, ) {
  listTranscriptsHistory(InstanceId: $InstanceId, customerEndpoint: $customerEndpoint, Cursor: $Cursor, CurrentDate:$CurrentDate, Channel: $Channel, EndDateRange:$EndDateRange) {
    items {
     AbsoluteTime
  ContactId
  Content
  ContentType
  DisplayName
  Id
  InitialContactId
  ParticipantId
  ParticipantRole
  Type
  id
  Author
  Direction
  BodyText
  CreatedAt
  Provider
  BodyUrl
  BodyId
  ConversationId
  From
  Subject
  To
  MediaUrls
  BodyHTML
  Attachments
  References
  MessageId
  Body
    }
    cursor
  }
}
`;
export const getListOfIntegrations = `query listIntegrations {
    listIntegrations {
        InstanceId
        Id
        Name
        Type
        Provider
        CreatedBy
        CreatedAt
        UpdatedAt
        Integration {
            __typename
            ... on SmsTroughPinpointIntegrationType {
                    ContactFlowId
                    PhoneNumber
                    PinpointAppId
                    ConnectArn
                    CloudFormationStackName
            }
            ... on Office365IntegrationType {
                ClientSecret
                ClientId
                TenantId
                SMTP
                Teams
                HasDelegatedSession
                EnabledChangeStatusInConnect
                ConnectToTeamsStatusesMap {
                    statusConnectPath
                    statusTeams
                    editable
                }
                TeamsToConnectStatusesMap {
                    statusConnectPath
                    statusTeams
                    editable
                }
            }
            ... on GoogleAuthIntegrationType {
                ClientId
                ClientSecret
                RefreshToken
            }
             ... on OfficeDynamicsIntegrationType {
                Uri
                Version
                AppId
                TenantId
                ClientId
                ClientSecret
                Scope
                RecordingBucketName
                IntegrationSteps {
                  Event
                  Step
                  Active
                  Entity
                  Action
                  RequestBody
                  QueryString
                  Tab
                }
            }
        }
    }
}`;

export const getIntegration = `query getIntegration($IntegrationId: String!) {
  getIntegration(IntegrationId: $IntegrationId) {
        InstanceId
        Id
        Name
        Type
        Provider
        CreatedBy
        CreatedAt
        UpdatedAt
        Integration {
            __typename
            ... on SmsTroughPinpointIntegrationType {
                    ContactFlowId
                    PhoneNumber
                    PinpointAppId
                    CloudFormationUrl
                    CloudFormationStackName
            }
            ... on Office365IntegrationType {
                ClientSecret
                ClientId
                TenantId
                SMTP
                Teams
                HasDelegatedSession
                EnabledChangeStatusInConnect
                ConnectToTeamsStatusesMap {
                    statusConnectPath
                    statusTeams
                    editable
                }
                TeamsToConnectStatusesMap {
                    statusConnectPath
                    statusTeams
                    editable
                }
            }
            ... on GoogleAuthIntegrationType {
                ClientId
                ClientSecret
                RefreshToken
            }
        }
    }
}`;

export const checkIntegrationCloudFormationStatus = `query checkIntegrationCloudFormationStatus($IntegrationId: String!) {
    checkIntegrationCloudFormationStatus(IntegrationId: $IntegrationId) {
        StackName
        StackStatus
    }
}`;

export const listConnectBackupVersions = `query listConnectBackupVersions($InstanceId: String!) {
  listConnectBackupVersions(InstanceId: $InstanceId) {
    items{
      VersionName
       VersionNumber
       InstanceId
      CreatedAt
      CreatedBy
    }
  }
}`;

export const getSkillsList = `query getSkillList($InstanceId: String) {
    getSkillList(InstanceId: $InstanceId){
    items {
      Title
      InstanceId
      id
      Level
    }
  }
}`;

export const listSkillUsers = `query listSkillUsers ($InstanceId: String, $SkillIds: [String]){
  listSkillUsers(InstanceId : $InstanceId , SkillIds : $SkillIds) {
    items {
      SkillIds
      isBeingCoached
      isBeingMonitored
      InstanceId
      Id
      Skills {
        id
        Title
        Status
        InstanceId
        Level
      }
      SkillsWeight
      Contact
      Contacts
      isConnectedToWebRTCCall
      StatusStartTimestamp
      NeedsHelp
      Username
      StatusName
      StatusType
      StatusDuration
      Platform
      Queues
      HeartBeat
      IdentityInfo {
        FirstName
        LastName
        Email
      }
      RoutingProfileId {
      Name
      Arn
      Id
      }
    }
  }
}`;
export const listSkillQueues = `query listSkillQueues($InstanceId: String!, $Queues: [String!], $DateRange: [String!], $Period: String, $IsHistorical: Boolean, $SkillIds : [String] ) {
  listSkillQueues(InstanceId: $InstanceId, Queues: $Queues, DateRange: $DateRange, Period: $Period, IsHistorical: $IsHistorical , SkillIds : $SkillIds) {
    items {
      id
      Arn
      Name
      Channel
      OutboundCallerConfig {
        OutboundCallerIdName
        OutboundCallerIdNumberId
        OutboundFlowId
      }
      Skills {
        id
        Title
        Status
        InstanceId
        Level
      }
      Status
      Open
      Description
      HoursOfOperationId
      QueueId
      QueueArn
      MaxContacts
      Tags
      Contacts {
        InitiationTimestamp
        id
        InitiationMethod
        CustomerEndpoint
        Channel
        Queue
      }
      ReportRange {
        start
        end
      }
      Performance {
        Name
        Status
        Open
        AvgACW
        AHT
        Holds
        HoldDuration
        HandledCallbacks
        LongestHold
        HandledIn
        HandledOut
        HandledSms
        HandledEmail
        HandledChat
        Missed
        Abandoned
      }
      AgentPerformance {
        AvgACW
        AHT
        Holds
        HoldDuration
        HandledCallbacks
        LongestHold
        HandledIn
        HandledOut
        HandledSms
        HandledEmail
        HandledChat
        Missed
        Username
      }
      DataSnapshotTime
      TimeSeriesInterval
      TimeSeriesData {
        AvgWaitTime
        AvgHandlingTime
        AvgAbandonTime
        AvgWrapTime
        AvgHoldTime
        OccupancyPct
        MaxWaitTime
        ContactsMissed
        AbandonedPct
        ServiceLvlPct
        ContactsQueued
        ContactsHandled
        ContactsHandledIn
        ContactsHandledCallbacks
        ContactsHandledOut
        ContactsAbandoned
        ContactsHoldAbandoned
        ContactsInQueueVoice
        ContactsInQueueChat
        ContactsInQueueEmail
        ContactsInQueueCallbacks
        ContactsInQueueSms
        ServiceLevel_10
        ServiceLevel_15
        ServiceLevel_20
        ServiceLevel_30
        ServiceLevel_60
        ServiceLevel_90
        ServiceLevel_120
        ServiceLevel_180
        ServiceLevel_240
        ServiceLevel_300
      }
      NewMetricData {
        AvgWaitTime
        AvgHandlingTime
        AvgAbandonTime
        AvgWrapTime
        AvgHoldTime
        OccupancyPct
        MaxWaitTime
        ContactsMissed
        AbandonedPct
        ServiceLvlPct
        ContactsQueued
        ContactsHandled
        ContactsHandledIn
        ContactsHandledCallbacks
        ContactsHandledOut
        ContactsAbandoned
        ContactsHoldAbandoned
        ContactsInQueueVoice
        ContactsInQueueChat
        ContactsInQueueEmail
        ContactsInQueueCallbacks
        ContactsInQueueSms
        ContactsTransferedExternally
        ServiceLevel_10
        ServiceLevel_15
        ServiceLevel_20
        ServiceLevel_30
        ServiceLevel_60
        ServiceLevel_90
        ServiceLevel_120
        ServiceLevel_180
        ServiceLevel_240
        ServiceLevel_300
      }
    }
    summary {
      Name
      Arn
      id
      TimeSeriesData {
        AvgWaitTime
        AvgHandlingTime
        AvgAbandonTime
        AvgWrapTime
        AvgHoldTime
        OccupancyPct
        MaxWaitTime
        ContactsMissed
        AbandonedPct
        ServiceLvlPct
        ContactsQueued
        ContactsHandled
        ContactsHandledIn
        ContactsHandledCallbacks
        ContactsHandledOut
        ContactsAbandoned
        ContactsHoldAbandoned
        ContactsInQueueVoice
        ContactsInQueueChat
        ContactsInQueueEmail
        ContactsInQueueCallbacks
        ContactsInQueueSms
        ContactsTransferedExternally
        ServiceLevel_10
        ServiceLevel_15
        ServiceLevel_20
        ServiceLevel_30
        ServiceLevel_60
        ServiceLevel_90
        ServiceLevel_120
        ServiceLevel_180
        ServiceLevel_240
        ServiceLevel_300
      }
      NewMetricData {
        AvgWaitTime
        AvgHandlingTime
        AvgAbandonTime
        AvgWrapTime
        AvgHoldTime
        OccupancyPct
        MaxWaitTime
        ContactsMissed
        AbandonedPct
        ServiceLvlPct
        ContactsQueued
        ContactsHandled
        ContactsHandledIn
        ContactsHandledCallbacks
        ContactsHandledOut
        ContactsAbandoned
        ContactsHoldAbandoned
        ContactsInQueueVoice
        ContactsInQueueChat
        ContactsInQueueEmail
        ContactsInQueueCallbacks
        ContactsInQueueSms
        ContactsTransferedExternally
        ServiceLevel_10
        ServiceLevel_15
        ServiceLevel_20
        ServiceLevel_30
        ServiceLevel_60
        ServiceLevel_90
        ServiceLevel_120
        ServiceLevel_180
        ServiceLevel_240
        ServiceLevel_300
      }
      Performance {
        Name
        Status
        Open
        AvgACW
        AHT
        Holds
        HoldDuration
        HandledCallbacks
        LongestHold
        HandledIn
        HandledOut
        HandledSms
        HandledEmail
        HandledChat
        Missed
        Abandoned
      }
      AgentPerformance {
        AvgACW
        AHT
        Holds
        HoldDuration
        HandledCallbacks
        LongestHold
        HandledIn
        HandledOut
        HandledSms
        HandledEmail
        HandledChat
        Missed
        Username
      }
      Contacts {
        InitiationTimestamp
        id
        InitiationMethod
        CustomerEndpoint
        Channel
        Queue
      }
    }
  }
}
`;

export const queueRebalancingListLog = `query queueRebalancingListLog($input: RebalancingLogInput!) {
  queueRebalancingListLog(input: $input) {
    Items{
      createdAt
      destinationQueueId
      destinationRoutingProfileId
      id
      type
      user
      users {
        Id
        Name {
          Email
          FirstName
          LastName
        }
        DestinationRoutingProfileId
        OriginRoutingProfileId
        UserName
      }
      versionId
    }
  }
}`;

export const getCustomsLinks = `query listCustomUrl {
  listCustomUrl {
    Id
    Name
    BaseUrl
    Type
    BrowserTab
    Scope
    AWSAgentID
  }
}`;

export const listUsersIntegrations = `query listUsersIntegrations($input: ListUsersIntegrationsInput!) {
  listUsersIntegrations(input: $input) {
    Items {
        CreatedAt
        Email
        FirstName
        Id
        LastName
        Platform
        IntegrationType
        StatusDuration
        StatusName
        StatusStartTimestamp
        StatusType
        UpdatedAt
        Username
        PhoneNumbers
    }
    Cursor
    Count
  }
}`;

export const getAttendeeVideoCallChime = `query getAttendeeVideoCallChime($MeetingId: String!, $AttendeeId: String!) {
  getAttendeeVideoCallChime(MeetingId: $MeetingId, AttendeeId: $AttendeeId) {
    AttendeeId
    Capabilities {
      Audio
      Content
      Video
    }
    ExternalUserId
    JoinToken
  }
}`;

export const getMeetingVideoCallChime = `query getMeetingVideoCallChime($MeetingId: String!) {
  getMeetingVideoCallChime(MeetingId: $MeetingId) {
    MeetingId
    ExternalMeetingId
    MediaPlacement {
      AudioFallbackUrl
      AudioHostUrl
      EventIngestionUrl
      ScreenDataUrl
      ScreenSharingUrl
      ScreenViewingUrl
      SignalingUrl
      TurnControlUrl
    }
    MediaRegion
    MeetingArn
  }
}`;

export const listAttendeesVideoCallChime = `query listAttendeesVideoCallChime($MeetingId: String!, $NextToken: String) {
  listAttendeesVideoCallChime(MeetingId: $MeetingId, NextToken: $NextToken) {
    Attendees {
      AttendeeId
      Capabilities {
        Audio
        Content
        Video
      }
      ExternalUserId
      JoinToken
    }
    NextToken:
  }
}`;

export const listCustomStacksIntegrations = `query listCustomStacksIntegrations {
  listCustomStacksIntegrations {
    items {
      id
      version
      deployedVersion
      name
      description
      templateUri
      templateName
      imageLogoUri
      status
      buttons
      outputs {
        name
        description
        value
      }
      templateParameters {
        order
        type
        name
        description
        required
        regex
        options
        value
      }
    }
  }
}`;

export const getCustomStackIntegration = `query getCustomStackIntegration($input: GetCustomStackIntegrationInput!) {
    getCustomStackIntegration(input: $input) {
        id
        version
        deployedVersion
        name
        description
        templateUri
        templateName
        imageLogoUri
        status
        buttons
        outputs {
            name
            description
            value
        }
        templateParameters {
            order
            type
            name
            description
            required
            regex
            options
            value
        }
    }
}
`;

export const getInstanceAttribute = `query getInstanceAttribute($input: InstanceAttrubuteInput!) {
  getInstanceAttribute(input: $input) {
    Attribute {
        Value
        AttributeType
    }
  }
}
`;
export const getListDashboards = `query listDashboards($input: DashboardListInput!) {
  listDashboards(input: $input) {
    DashboardSummaryList {
      Arn
      DashboardId
      Name
      CreatedTime
      LastUpdatedTime
      PublishedVersionNumber
      LastPublishedTime
      Access
      Type
    }
  }
}
`;

export const getQuickSightEmbedURL = `query getQuickSightEmbedURL($input: QuickSightEmbedURLInput!) {
  getQuickSightEmbedURL(input: $input) {
    AnonymousUserArn
    EmbedUrl
  }
}
`;

export const createQuickSight = `query createQuickSight($input: CreateQuickSightInput!) {
  createQuickSight(input: $input)
}
`;

export const listTaskTemplates = `query($input: TaskTemplatesInput!) {
  listTaskTemplates(input: $input) {
    TaskTemplates {
      Id
      Name
      Status
    }
  }
}
`;
export const getSignedS3URL = `query getSignedS3URL($bucketName: String! $key: String! $method:String $contentType: String) {
  getSignedS3URL(
       bucketName: $bucketName
       key: $key
       method: $method
       contentType: $contentType
  ) {
   items {
        Bucket
        InstanceId
        Url
        Expiration
        Key
        Method
        ResponseContentType
   }
  }
}`;

export const getMeetingChimeInfo = `query GetMeetingChime($input: GetMeetingAttributesInput!) {
    getMeetingChime(input: $input) {
        MeetingId
        ExternalMeetingId
        MediaRegion
        MeetingArn
        UrlLink
        ConnectingTo
        InstanceId
        Initiator
        MediaPlacement {
            AudioFallbackUrl
            AudioHostUrl
            EventIngestionUrl
            ScreenDataUrl
            ScreenSharingUrl
            ScreenViewingUrl
            SignalingUrl
            TurnControlUrl
        }
    }
}
`;
export const replicateInstance = `query ReplicateInstance($input: ReplicateInstanceInput!) {
    replicateInstance(input: $input)
}`;

export const replicateCrossRegionResources = `query ReplicateCrossRegionResources($input: ReplicateCrossRegionResourcesInput!) {
    replicateCrossRegionResources(input: $input )
}`;

export const listTrafficDistributionGroups = `query listTrafficDistributionGroups($InstanceId: String!){
  listTrafficDistributionGroups(InstanceId: $InstanceId) {
    Items {
      Arn
      Id
      InstanceArn
      IsDefault
      Name
      Status
    }
  }
}`;

export const listTrafficDistributionGroupUsers = `query listTrafficDistributionGroupUsers($TrafficDistributionGroupId: String!){
  listTrafficDistributionGroupUsers(TrafficDistributionGroupId: $TrafficDistributionGroupId) {
    Items {
  UserId
    }
  }
}`;

export const listPhoneNumbers = `query listPhoneNumbers ($InstanceId:String!){
  listPhoneNumbers(InstanceId: $InstanceId) {
    Items {
        InstanceId
        PhoneNumber
        PhoneNumberArn
        PhoneNumberCountryCode
        PhoneNumberId
        PhoneNumberType
        SourcePhoneNumberArn
        TargetArn

      }
    }
}`;

export const describeTrafficDistributionGroup = `query describeTrafficDistributionGroup ($Id: String!){
    describeTrafficDistributionGroup(Id: $Id) {
        Arn
        Description
        Id
        InstanceArn
        IsDefault
        Name
        Status
  }
}`;

export const describePhoneNumber = `query describePhoneNumber ($PhoneNumberId:String!){
  describePhoneNumber(PhoneNumberId: $PhoneNumberId) {
      InstanceId
      PhoneNumber
      PhoneNumberArn
      PhoneNumberCountryCode
      PhoneNumberDescription
      PhoneNumberId
      PhoneNumberType
      SourcePhoneNumberArn
      TargetArn
      PhoneNumberStatus{
        Message
        Status
      }
    }
}`;

export const getTrafficDistribution = `query getTrafficDistribution ($Id:String!){
  getTrafficDistribution(Id: $Id) {
    Id
    Arn
    TelephonyConfig{
      Distributions{
        Percentage
        Region}
      }
    SignInConfig {
      Distributions{
        Enabled
        Region}
    }
    AgentConfig {
      Distributions{
        Percentage
        Region}
    }
  }
}`;

export const getChimeAgentMessages = `query getAgentMessage ($ChannelArn:String!){
  getAgentMessage(ChannelArn: $ChannelArn) {
    ConversationId
    Messages {
      CreatedTimestamp
      LastUpdatedTimestamp
      MessageId
      Redacted
      Sender{
        Arn
        Name
      }
      Type
      Content
    }
  }
}`;

export const getAgentMessageChannel = `query getAgentMessageChannel ($input: AgentMessageChannelInput!){
  getAgentMessageChannel(input:$input) {
    ChannelArn    
    id
  }
}`;
